/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hello,"), "\n", React.createElement(_components.p, null, "Some big changes are happening at Signatu, and here’s a quick summary of what’s going on."), "\n", React.createElement(_components.p, null, "First, we have implemented a lot of improvements to the product. We cannot list them all here, but here are some highlights:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Improved ", React.createElement(_components.a, {
    href: "https://signatu.com/app/policy"
  }, "Privacy Policy questionnaire"), ", including integration with the ", React.createElement(_components.a, {
    href: "https://signatu.com/app/dataprocessing/dashboard"
  }, "Data Processing"), ", support for 10 European languages and easier theming of colors and fonts."), "\n", React.createElement(_components.li, null, "Consent ", React.createElement(_components.a, {
    href: "https://signatu.com/app/consent/analytics"
  }, "Analytics"), " and ", React.createElement(_components.a, {
    href: "https://signatu.com/app/consent/search"
  }, "Search"), " tools."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://signatu.com/app/trackerdetect/sites"
  }, "Trackerdetect"), " introduces Site Sharing and Page Responsible, allowing organizations to set up effective governance of their web sites."), "\n", React.createElement(_components.li, null, "Channels support receiving events from Signatu through Webhooks, Amazon Web Services SQS, and Azure Service Bus."), "\n", React.createElement(_components.li, null, "New, comprehensive ", React.createElement(_components.a, {
    href: "https://signatu.com/docs"
  }, "technical documentation"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://signatu.com/legal/guides"
  }, "Legal guides"), " to help you use Signatu for your legal compliance work."), "\n"), "\n", React.createElement(_components.p, null, "Second, we are simplifying our ", React.createElement(_components.a, {
    href: "https://signatu.com/product/plans"
  }, "plans and payment structure"), ". The Basic tier (€50 per month, €500 per year), and Professional tier (€100 per month, €1000 per year) have a 30 day Free Trial and are paid by Credit Card. We also offer an Enterprise tier for customers with multiple sites and/or additional needs."), "\n", React.createElement(_components.p, null, "Note that if you already have a subscription with Signatu (invoice billing) your account is already set up - no action is required from you."), "\n", React.createElement(_components.p, null, "We’d love your feedback - good and bad - so don’t hesitate to send us your thoughts on ", React.createElement(_components.a, {
    href: "mailto:hello@signatu.com"
  }, "hello@signatu.com"), "."), "\n", React.createElement(_components.p, null, "We wish you a great summer!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
